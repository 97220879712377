<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row" v-if="state.id">
                                    <label class="col-sm-12 col-form-label">{{ state.id }}</label>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Titulo</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Parametros (JSON)</label>
                                    <div class="col-sm-9">
                                        <JsonTextareas
                                            class="form-control"
                                            rows="5"
                                            cols="5"
                                            placeholder="{}"
                                            v-model="state.params"
                                        ></JsonTextareas>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Componente</label>
                                    <div class="col-sm-9">
                                        <treeselect
                                            v-model="state.component"
                                            :options="darminComponents"
                                        ></treeselect>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Orden</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="number"
                                            placeholder="#"
                                            v-model="state.order"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Parent</label>
                                    <div class="col-sm-9">
                                        <treeselect
                                            v-model="state.parent"
                                            :options="parentsList"
                                        ></treeselect>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Key</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.key"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Parent Key</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.parentKey"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <SelectInputSimpleState v-model="state.status" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div class="btn btn-primary ms-auto" @click="handleAction">
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { defineComponent, onBeforeMount, onMounted, ref, reactive, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useSnackbar } from 'vue3-snackbar';
import WindowLayout from '@/layouts/Window.layout.vue';
import Treeselect from 'vue3-treeselect';
// eslint-disable-next-line import/no-cycle
import DarminComponents from '@/DarminComponents';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';
import useMenuEditor from './services/useMenuEditor';
import JsonTextareas from '../../../components/form/JsonTextareas.vue';

export default defineComponent({
    components: {
        WindowLayout,
        Treeselect,
        SelectInputSimpleState,
        JsonTextareas,
    },
    props: {
        title: String,
        headerTitle: String,
        urlRead: String,
        urlCreate: String,
        urlUpdate: String,
        item: Object,
    },
    setup(props) {
        const darminComponents = ref([]);
        const parentsList = ref([]);
        const snackbar = useSnackbar();
        const { loadMenuTreeForSelect, createItem, updateItem } = useMenuEditor();

        const state = reactive({
            id: '',
            name: '',
            key: '',
            params: '',
            component: null,
            order: '',
            parent: null,
            parentKey: '',
            code: '',
            parentCode: '',
            status: '1',
        });

        const rules = computed(() => ({
            name: { required },
            order: { required },
            status: { required },
            key: { required },
        }));

        const $v = useVuelidate(rules, state);

        onBeforeMount(async () => {
            darminComponents.value = (await DarminComponents()).getSelect();
            if (props.item) {
                state.id = props.item.id;
                state.name = props.item.name;
                state.key = props.item.key;
                state.params = JSON.stringify(props.item.params);
                state.component = props.item.component;
                state.order = Number(props.item.order);
                state.parent = props.item.parent;
                state.parentKey = props.item.parentKey;
                state.code = props.item.code;
                state.parentCode = props.item.parentCode;
                state.status = props.item.status.toString();
            }
            console.log('state.parent', state.parent);
        });

        onMounted(async () => {
            const res = await Promise.all([loadMenuTreeForSelect()]);
            parentsList.value = [...res[0]];
        });

        const onParent = (value) => {
            state.parent = value;
        };

        const onStatus = (value) => {
            state.status = value;
        };

        const handleAction = async () => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                if (props.item) {
                    await updateItem(props.item.id, {
                        name: state.name,
                        key: state.key,
                        params: JSON.parse(state.params || '{}'),
                        component: state.component,
                        order: state.order,
                        parent: state.parent,
                        parentKey: state.parent,
                        code: state.code,
                        parentCode: state.parentCode,
                        status: Number(state.status),
                    })
                        .then(() => {
                            snackbar.add({
                                type: 'success',
                                text: 'Item ha sido actualizado',
                            });
                        })
                        .catch((error) => {
                            error.data.errors.map((e) => {
                                snackbar.add({
                                    type: 'error',
                                    text: e.msg,
                                });
                                return true;
                            });
                        });
                } else {
                    await createItem({
                        name: state.name,
                        key: state.key,
                        params: JSON.parse(state.params || '{}'),
                        component: state.component,
                        order: state.order,
                        parent: state.parent,
                        parentKey: state.parentKey,
                        code: state.code,
                        parentCode: state.parentCode,
                        status: Number(state.status),
                    })
                        .then(async () => {
                            snackbar.add({
                                type: 'success',
                                text: 'Item ha sido agregado',
                            });
                            state.name = '';
                            state.key = '';
                            state.params = '';
                            state.component = null;
                            state.order = '';
                            state.parent = null;
                            state.parentKey = '';
                            state.status = '1';
                            const res = await Promise.all([loadMenuTreeForSelect()]);
                            parentsList.value = [...res[0]];
                        })
                        .catch((error) => {
                            error.data.errors.map((e) => {
                                snackbar.add({
                                    type: 'error',
                                    text: e.msg,
                                });
                                return true;
                            });
                        });
                }
            } else {
                console.log($v.value.$errors);
            }
        };

        return {
            state,
            $v,
            parentsList,
            onParent,
            onStatus,
            handleAction,
            darminComponents,
        };
    },
});
</script>

<style scoped></style>
