<template>
    <textarea v-model="value.modelValue" @input="prettyPrint" />
</template>

<script>
import { reactive } from 'vue';

export default {
    name: 'JsonTextarea',
    props: {
        modelValue: String,
    },
    setup(props, { emit }) {
        const value = reactive(props);
        const prettyPrint = (event) => {
            try {
                emit(
                    'update:modelValue',
                    // eslint-disable-next-line comma-dangle
                    JSON.stringify(JSON.parse(event.target.value), undefined, 4)
                );
            } catch (error) {
                emit('update:modelValue', event.target.value);
            }
        };
        return { value, prettyPrint };
    },
};
</script>

<style></style>
