import axios from '@/services/useAxios';

export default function useMenuEditor() {
    const loadMenuTree = async () => {
        const res = await axios.get('/menu/tree');
        if (res.status === 200) {
            return res.data;
        }
        throw new Error(res.data.errors);
    };

    const loadMenuTreeForSelect = async () => {
        const res = await axios.get('/menu/tree/select');
        if (res.status === 200) {
            return res.data;
        }
        throw new Error(res.data.errors);
    };

    const createItem = async (item) => {
        const res = await axios.post('/menu', item);
        if (res.status === 200) {
            return res.data;
        }
        throw new Error(res.data.errors);
    };

    const updateItem = async (id, item) => {
        const res = await axios.put(`/menu/${id}`, item);
        if (res.status === 200) {
            return res.data;
        }
        throw new Error(res.data.errors);
    };

    const toggleItem = async (id) => {
        const res = await axios.put(`/menu/toggle/${id}`);
        if (res.status === 200) {
            return res.data;
        }
        throw new Error(res.data.errors);
    };

    const removeItem = async (id) => {
        try {
            const res = await axios.delete(`/menu/${id}`);
            if (res.status === 200) {
                return res.data;
            }
            throw res.data.errors;
        } catch (error) {
            throw error.data.errors;
        }
    };

    return {
        loadMenuTree,
        loadMenuTreeForSelect,
        createItem,
        updateItem,
        toggleItem,
        removeItem,
    };
}
